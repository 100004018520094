export const FirebaseConfig = {
	"projectId": "port-richmond-black-car",
	"appId": "1:771062404443:web:99cfbe9c8885a1a397767e",
	"databaseURL": "https://port-richmond-black-car-default-rtdb.firebaseio.com",
	"storageBucket": "port-richmond-black-car.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDWLNmwuIU1S0ZDAYxmmovd71TBNa0ICP8",
	"authDomain": "port-richmond-black-car.firebaseapp.com",
	"messagingSenderId": "771062404443",
	"measurementId": "G-F1QYGQJJW3"
};